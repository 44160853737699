<div class="section section-hero">
  <div class="section-inner">
    <div class="container-sm">
      <h1 class="h2">Contributing Teams</h1>
    </div>
  </div>
</div>

<div class="section">
  <div class="section-inner">
    <div class="container-sm">
      <team-list [teams]="teams"></team-list>
    </div>
  </div>
</div>
