<button
  type="button"
  class="btn btn-rounded btn-primary"
  (click)="op.toggle($event)"
>
  <i class="fa-solid fa-download"></i>
</button>
<p-overlayPanel
  #op
  styleClass="download-dom-image-panel"
  (rotate)="onRotate()"
  (window:resize)="onResize()"
>
  <div class="download-dom-image-header">
    {{ heading }}
  </div>
  <div class="download-dom-image-body">
    <div *ngFor="let type of types">
      <p-radioButton
        value="{{ type.value }}"
        label="{{ type.label }}"
        [(ngModel)]="selectedType"
      ></p-radioButton>
    </div>
    <div>
      <button
        pButton
        type="button"
        label="Download"
        class="btn-rounded btn-primary"
        (click)="download()"
      >
        <i *ngIf="isLoading" class="fas fa-spinner fa-spin"></i>
      </button>
      <div *ngIf="error" class="download-dom-image-error">{{ error }}</div>
    </div>
  </div>
</p-overlayPanel>
