<div class="gene-network">
  <div *ngIf="data?.nodes?.length" class="row">
    <div class="col-lg-8">
      <div class="gene-network-chart">
        <p class="gene-network-filters-title">Filter by Number of Edges</p>

        <div *ngIf="filters?.length" class="gene-network-filters">
          <div class="gene-network-filters-inner">
            <span>&gt;0</span>
            <div
              *ngFor="let n of filters"
              [ngClass]="{ active: n === selectedFilter }"
            >
              <a
                (click)="selectedFilter = n"
                pTooltip="&gt;{{ n - 1 }}"
                tooltipPosition="top"
                tooltipStyleClass="tooltip"
              ></a>
            </div>
            <span>&gt;{{ filters[filters.length - 1] - 1 }}</span>
          </div>
        </div>

        <network-chart
          [data]="data"
          [selectedFilter]="selectedFilter"
          (onNodeClick)="onNodeClick($event)"
        ></network-chart>

        <div class="gene-network-legend">
          <div class="gene-network-legend-inner">
            <div>
              <svg
                width="18"
                height="20"
                viewBox="0 0 18 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 0L17.6603 5V15L9 20L0.339746 15V5L9 0Z"
                  fill="#8B8AD1"
                />
              </svg>
              Current gene
            </div>
            <div>Selected gene</div>
            <div>2-3 Edges</div>
            <div>4-5 Edges</div>
            <div>6-7 Edges</div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="selectedGene" class="col-lg-4">
      <div class="gene-network-selected">
        <h3>
          {{ selectedGene.hgnc_symbol || selectedGene.ensembl_gene_id }}
        </h3>

        <p>{{ selectedGene.summary }}</p>

        <div class="gene-network-selected-details">
          <div>
            <div>Genetic Association with LOAD</div>
            <div>
              {{ getText(selectedGene.is_igap) }}
            </div>
          </div>
          <div>
            <div>Brain eQTL</div>
            <div>
              {{ getText(selectedGene.is_eqtl) }}
            </div>
          </div>
          <div>
            <div>RNA Expression Change in AD Brain</div>
            <div>
              {{
                getText(
                  selectedGene.is_any_rna_changed_in_ad_brain,
                  selectedGene.rna_brain_change_studied
                )
              }}
            </div>
          </div>
          <div>
            <div>Protein Expression Change in AD Brain</div>
            <div>
              {{
                getText(
                  selectedGene.is_any_protein_changed_in_ad_brain,
                  selectedGene.protein_brain_change_studied
                )
              }}
            </div>
          </div>
          <div>
            <div>Nominated target</div>
            <div>
              {{ getNominationText(selectedGene.total_nominations) }}
            </div>
          </div>
        </div>

        <hr class="separator separator-md" />

        <div class="gene-network-selected-similar">
          <div class="gene-network-selected-similar-list">
            <div
              *ngFor="
                let node of selectedGene.similar_genes_network?.nodes
                  | slice: 1:6
              "
            >
              <a
                href="/genes/{{ node.ensembl_gene_id }}"
                class="genelink"
                target="_blank"
              >
                {{ node.hgnc_symbol || node.ensembl_gene_id }}
              </a>
            </div>
          </div>
          <div class="gene-network-selected-similar-link">
            <div>
              View all genes that show similar expression to the selected gene,
              and learn how these genes are associated with AD.
            </div>
            <div>
              <i
                (click)="navigateToSimilarGenes()"
                class="fa fa-angle-right"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!data?.nodes?.length" class="gene-network-no-data">
    <div class="gene-network-no-data-text">No data is currently available.</div>
  </div>
</div>
