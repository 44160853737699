<div [ngClass]="['gene-table', className]">
  <p-table
    #table
    [value]="_genes"
    [columns]="_columns"
    [paginator]="false"
    [responsive]="false"
    [scrollable]="true"
    scrollDirection="both"
    [customSort]="true"
    (sortFunction)="customSort($event)"
    [exportFilename]="exportFilename"
    [ngClass]="getFullscreenClass()"
    [sortField]="sortField"
    [sortOrder]="sortOrder"
  >
    <ng-template #header pTemplate="caption">
      <div>{{ heading }}</div>
      <div class="gene-table-header-controls">
        <div *ngIf="gctLink">
          <div
            [pTooltip]="gctLinkTooltip"
            tooltipPosition="top"
            tooltipStyleClass="tooltip gene-table-tooltip"
            appendTo="target"
          >
            <i
              class="fa-solid fa-arrow-right-arrow-left"
              (click)="navigateToGeneComparisonTool()"
            ></i>
          </div>
        </div>
        <div>
          <div
            pTooltip="View table in full screen"
            tooltipPosition="top"
            tooltipStyleClass="tooltip tooltip-nowrap gene-table-tooltip"
            appendTo="target"
          >
            <i [ngClass]="getWindowClass()" (click)="toggleFullscreen()"></i>
          </div>
        </div>
        <div>
          <div
            pTooltip="Download table as CSV"
            tooltipPosition="top"
            tooltipStyleClass="tooltip tooltip-nowrap gene-table-tooltip"
            appendTo="target"
          >
            <i class="fa-solid fa-download" (click)="table.exportCSV()"></i>
          </div>
        </div>
        <div>
          <div
            pTooltip="Control the columns that are displayed in this table"
            tooltipPosition="top"
            tooltipStyleClass="tooltip gene-table-tooltip"
            appendTo="target"
          >
            <p-multiSelect
              [options]="optionalColumns"
              [(ngModel)]="_selectedColumns"
              [showHeader]="false"
              optionLabel="header"
              dropdownIcon="pi pi-filter"
            ></p-multiSelect>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th
          *ngFor="let column of columns; let i = index"
          [style]="{ width: column.width + 'px' }"
          [ngClass]="{ hidden: !selectedColumns.includes(column) }"
          [ngSwitch]="column.field"
        >
          <div class="tr">
            <div [pSortableColumn]="column.field" class="th">
              {{ column.header }}
              <p-sortIcon [field]="column.field"></p-sortIcon>
            </div>
          </div>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item let-columns="columns">
      <tr (click)="navigateToGene(item)">
        <td
          *ngFor="let column of columns"
          [style]="{ width: column.width + 'px' }"
          [ngClass]="{ hidden: !selectedColumns.includes(column) }"
        >
          {{ item[column.field] }}
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
