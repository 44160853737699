<div class="section section-hero">
  <div class="section-inner">
    <div class="container-sm">
      <h1 class="h2">Agora News</h1>
    </div>
  </div>
</div>

<div class="section">
  <div class="section-inner">
    <div class="container-sm">
      <h2>What’s New in Agora</h2>
      <wiki [wikiId]="wikiId" [className]="className"></wiki>
    </div>
  </div>
</div>
