<div class="chart network-chart" (window:resize)="onResize()">
  <div class="chart-inner">
    <div class="chart-body">
      <div #chartContainer class="chart-container"></div>
    </div>
    <div class="chart-no-data" *ngIf="!this._data?.nodes?.length">
      <div class="chart-no-data-text">No data is currently available.</div>
    </div>
  </div>
</div>
