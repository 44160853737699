<div class="gct-filter-list">
  <div *ngIf="shouldShowList()" class="gct-filter-list-inner">
    <div>
      <button class="gct-filter-list-clear-all" (click)="clearList()">
        <svg-icon name="trash" customClass="two-tone"></svg-icon>
        <span>Clear all</span>
      </button>
    </div>
    <div>
      <gene-comparison-tool-filter-list-item
        [item]=""
        [description]="'Significance &leq; ' + significanceThreshold"
        [isVisible]="significanceThresholdActive"
        (onClear)="removeSignificanceThresholdFilter()"
      ></gene-comparison-tool-filter-list-item>
      <ng-container *ngFor="let filter of filters; index as i">
        <ng-container *ngFor="let option of filter.options; index as j">
          <gene-comparison-tool-filter-list-item
            [item]="option"
            [title]="filter.short || ''"
            [description]="option.label"
            [isVisible]="option.selected || false"
            (onClear)="clearSelectedFilters(option)"
          ></gene-comparison-tool-filter-list-item>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
