<svg
  *ngIf="name === 'info-circle'"
  [ngClass]="getClasses()"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  viewBox="0 0 20 20"
  xml:space="preserve"
>
  <circle class="svg-icon-bg" fill="transparent" cx="10" cy="10" r="9.5" />
  <path
    d="M9,15h2V9H9V15z M10,0C4.5,0,0,4.5,0,10s4.5,10,10,10s10-4.5,10-10S15.5,0,10,0z M10,18c-4.4,0-8-3.6-8-8s3.6-8,8-8 s8,3.6,8,8S14.4,18,10,18z M9,7h2V5H9V7z"
    fill="currentColor"
  />
</svg>

<svg
  *ngIf="name === 'cog'"
  [ngClass]="getClasses()"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  viewBox="0 0 20 20"
  xml:space="preserve"
>
  <path
    class="svg-icon-bg"
    fill="transparent"
    d="M8.4,19L8,16.2L7.5,16c-0.5-0.2-1-0.5-1.5-0.8l-0.5-0.3l-2.6,1l-1.6-2.7l2.2-1.7l-0.1-0.6
		c0-0.3-0.1-0.6-0.1-0.9s0-0.6,0.1-0.9l0.1-0.6L1.4,6.9L3,4.1l2.6,1L6,4.9C6.5,4.5,7,4.2,7.5,4L8,3.8L8.4,1h3.1L12,3.8L12.5,4
		c0.5,0.2,1,0.5,1.5,0.8l0.5,0.3l2.6-1l1.6,2.7l-2.2,1.7l0.1,0.6c0,0.3,0.1,0.6,0.1,0.9s0,0.6-0.1,0.9l-0.1,0.6l2.2,1.7L17,15.9
		l-2.6-1L14,15.1c-0.5,0.4-1,0.7-1.5,0.9L12,16.2L11.6,19H8.4z"
  />
  <path
    fill="currentColor"
    d="M17.4,11c0-0.3,0.1-0.6,0.1-1c0-0.3,0-0.7-0.1-1l2.1-1.6c0.2-0.1,0.2-0.4,0.1-0.6l-2-3.5
	C17.6,3.1,17.4,3,17.2,3c-0.1,0-0.1,0-0.2,0l-2.5,1c-0.5-0.4-1.1-0.7-1.7-1l-0.4-2.6C12.5,0.2,12.3,0,12,0H8C7.8,0,7.5,0.2,7.5,0.4
	L7.1,3.1C6.5,3.3,6,3.7,5.4,4.1L3,3.1C2.9,3,2.8,3,2.8,3C2.6,3,2.4,3.1,2.3,3.3l-2,3.5C0.2,7,0.3,7.2,0.5,7.4L2.6,9
	c0,0.3-0.1,0.7-0.1,1c0,0.3,0,0.7,0.1,1l-2.1,1.6c-0.2,0.1-0.2,0.4-0.1,0.6l2,3.5C2.4,16.9,2.6,17,2.8,17c0.1,0,0.1,0,0.2,0l2.5-1
	c0.5,0.4,1.1,0.7,1.7,1l0.4,2.6C7.5,19.8,7.8,20,8,20h4c0.2,0,0.5-0.2,0.5-0.4l0.4-2.6c0.6-0.3,1.2-0.6,1.7-1l2.5,1
	c0.1,0,0.1,0,0.2,0c0.2,0,0.3-0.1,0.4-0.2l2-3.5c0.1-0.2,0.1-0.5-0.1-0.6L17.4,11z M15.5,9.3c0,0.3,0,0.5,0,0.7c0,0.2,0,0.4,0,0.7
	l-0.1,1.1l0.9,0.7l1.1,0.8l-0.7,1.2l-1.3-0.5l-1-0.4l-0.9,0.7c-0.4,0.3-0.8,0.6-1.2,0.7l-1.1,0.4l-0.2,1.1L10.7,18H9.3l-0.2-1.3
	L9,15.5l-1.1-0.4c-0.4-0.2-0.8-0.4-1.2-0.7l-0.9-0.7l-1.1,0.4l-1.3,0.5l-0.7-1.2l1.1-0.8l0.9-0.7l-0.1-1.1c0-0.3-0.1-0.5-0.1-0.7
	c0-0.2,0-0.4,0.1-0.7l0.1-1.1L3.8,7.4L2.7,6.6l0.7-1.2l1.3,0.5l1,0.4l0.9-0.7c0.4-0.3,0.8-0.6,1.3-0.7l1.1-0.4l0.2-1.1L9.3,2h1.4
	l0.2,1.3L11,4.5l1.1,0.4c0.4,0.2,0.8,0.4,1.2,0.7l0.9,0.7l1.1-0.4l1.3-0.5l0.7,1.2l-1.1,0.9l-0.9,0.7L15.5,9.3z M10,6
	c-2.2,0-4,1.8-4,4c0,2.2,1.8,4,4,4c2.2,0,4-1.8,4-4C14,7.8,12.2,6,10,6z M10,12c-1.1,0-2-0.9-2-2s0.9-2,2-2c1.1,0,2,0.9,2,2
	S11.1,12,10,12z"
  />
  <path fill="currentColor" d="M32.9,6.1" />
</svg>

<svg
  *ngIf="name === 'trash'"
  [ngClass]="getClasses()"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  viewBox="0 0 15.6 20"
  xml:space="preserve"
>
  <rect
    class="svg-icon-bg"
    fill="transparent"
    x="2.4"
    y="5.7"
    width="10.9"
    height="13.1"
  />
  <path
    fill="currentColor"
    d="M11.7,1.1L10.6,0H5L3.9,1.1H0v2.2h15.6V1.1H11.7z M1.1,17.8c0,1.2,1,2.2,2.2,2.2h8.9c1.2,0,2.2-1,2.2-2.2V4.4H1.1V17.8z
  M3.4,6.7h8.9v11.1H3.4V6.7z"
  />
</svg>

<svg
  *ngIf="name === 'external-link'"
  [ngClass]="getClasses()"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  viewBox="0 0 20 20"
  xml:space="preserve"
>
  <path
    fill="currentColor"
    d="M17.8,17.8H2.2V2.2H10V0H2.2C1,0,0,1,0,2.2v15.6C0,19,1,20,2.2,20h15.6c1.2,0,2.2-1,2.2-2.2V10h-2.2V17.8z M12.2,0v2.2h4
	L5.3,13.1l1.6,1.6L17.8,3.8v4H20V0H12.2z"
  />
</svg>

<svg
  *ngIf="name === 'pin'"
  [ngClass]="getClasses()"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  viewBox="0 0 14 20"
  xml:space="preserve"
>
  <path
    class="svg-icon-bg"
    fill="transparent"
    d="M2,11l1.2-1.6C3.7,8.7,4,7.9,4,7V1h6v6c0,0.9,0.3,1.7,0.8,2.4L12,11H2z"
  />
  <path
    fill="currentColor"
    d="M9,2v5c0,1.1,0.4,2.2,1,3H4c0.7-0.9,1-1.9,1-3V2H9z M12,0H2C1.4,0,1,0.4,1,1s0.4,1,1,1h1v5c0,1.7-1.3,3-3,3v2h6v7l1,1l1-1
	v-7h6v-2c-1.7,0-3-1.3-3-3V2h1c0.5,0,1-0.5,1-1S12.5,0,12,0z"
  />
</svg>

<svg
  *ngIf="name === 'download'"
  [ngClass]="getClasses()"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  viewBox="0 0 16.5 20"
  xml:space="preserve"
>
  <path
    class="svg-icon-bg"
    fill="transparent"
    d="M9.4,7.1V2.4H7.1v7.1H5.7L8.2,12l2.6-2.6H9.4V7.1z"
  />
  <path
    fill="currentColor"
    d="M11.8,7.1V0H4.7v7.1H0l8.2,8.2l8.2-8.2H11.8z M8.2,12L5.7,9.4h1.4V2.4h2.4v7.1h1.4L8.2,12z M0,17.6h16.5V20H0V17.6z"
  />
</svg>

<svg 
  *ngIf="name === 'column'"
  [ngClass]="getClasses()"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  viewBox="0 0 20 16"
  xml:space="preserve"
>
  <path 
    class="svg-icon-bg"
    d="M19 0H1C0.45 0 0 0.45 0 1V15C0 15.55 0.45 16 1 16H19C19.55 16 20 15.55 20 15V1C20 0.45 19.55 0 19 0ZM6 14H2V2H6V14ZM12 14H8V2H12V14ZM18 14H14V2H18V14Z" 
    fill="currentColor"/>
</svg>
  