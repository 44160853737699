<div *ngIf="isVisible" class="gct-filter-list-item">
  <div class="gct-filter-list-item-text">
    <b *ngIf="title !== ''">{{ title }}:&nbsp;</b
    ><span *ngIf="description !== ''">{{ description }}</span>
  </div>
  <div>
    <button class="gct-filter-list-item-clear" (click)="clearWasClicked()">
      <svg
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M1 9L9 1M1 1L9 9" stroke="currentColor" stroke-width="2" />
      </svg>
    </button>
  </div>
</div>
