<div class="loading-icon">
  <div class="loading-icon-inner">
    <div class="spinner">
      <div class="spinner-container">
        <div class="hex0"></div>
        <div class="hex120"></div>
        <div class="hex240"></div>
        <div class="spinner">
          <div class="spinner-container">
            <div class="hex0"></div>
            <div class="hex120"></div>
            <div class="hex240"></div>
            <div class="spinner">
              <div class="spinner-container">
                <div class="hex0"></div>
                <div class="hex120"></div>
                <div class="hex240"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
