<div
  class="gct-filter-panel"
  [ngClass]="{ open: isOpen, active: activePane > -1 }"
>
  <div class="gct-filter-panel-inner">
    <div class="gct-filter-panel-main">
      <div class="gct-filter-panel-main-inner">
        <div class="gct-filter-panel-main-top">
          <button class="gct-filter-panel-close" (click)="close()">
            <svg
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.9999 1L1.3999 19.6M1.3999 1L19.9999 19.6"
                stroke="#C4C4C4"
                stroke-width="2"
              />
            </svg>
          </button>
        </div>
        <div class="gct-filter-panel-main-heading">Filter Genes By</div>
        <div class="gct-filter-panel-main-menu">
          <ul>
            <li
              *ngFor="let filter of filters; index as i"
              class="gct-filter-panel-main-menu-item-{{ filter.name }}"
              [ngClass]="{ active: activePane === i }"
            >
              <button (click)="openPane(i)">
                <div>
                  {{ filter.label }}
                </div>
                <div>
                  <svg
                    width="9"
                    height="15"
                    viewBox="0 0 9 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.999999 1.59998L7 7.59998L1 13.6"
                      stroke="currentColor"
                      stroke-width="2"
                    />
                  </svg>
                </div>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="gct-filter-panel-panes">
      <div
        *ngFor="let filter of filters; index as i"
        class="gct-filter-panel-pane gct-filter-panel-pane-{{ filter.name }}"
        [ngClass]="{ open: activePane === i }"
      >
        <div class="gct-filter-panel-pane-inner">
          <div class="gct-filter-panel-pane-top">
            <button class="gct-filter-panel-close" (click)="close()">
              <svg
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.9999 1L1.3999 19.6M1.3999 1L19.9999 19.6"
                  stroke="#C4C4C4"
                  stroke-width="2"
                />
              </svg>
            </button>
          </div>
          <div class="gct-filter-panel-pane-heading">
            {{ filter.label }}
            <div
              class="gct-filter-panel-pane-heading-info info-icon"
              [pTooltip]="filter.description || ''"
              tooltipPosition="top"
              tooltipStyleClass="tooltip gct-tooltip-filter"
            >
              <svg-icon name="info-circle" customClass="two-tone"></svg-icon>
            </div>
          </div>
          <ul>
            <li *ngFor="let option of filter.options; index as j">
              <label>
                <div>
                  <p-checkbox
                    [(ngModel)]="option.selected"
                    [binary]="true"
                    (ngModelChange)="handleChange(option)"
                  ></p-checkbox>
                </div>
                <div>
                  {{ option.label }}
                </div>
              </label>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
