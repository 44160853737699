<div
  #root
  [class]="'gene-search gene-search-' + location"
>
  <div class="gene-search-bar">
    <div class="gene-search-bar-icon">
      <i
        *ngIf="location === 'header' && !isLoading"
        class="fas fa-magnifying-glass"
      ></i>

      <svg
        *ngIf="location === 'home' && !isLoading"
        width="45"
        height="45"
        viewBox="0 0 192 192"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M75.928 64.432V8v56.432Z" fill="#fff" />
        <path
          d="M75.928 64.432V8"
          stroke="#42C7BB"
          stroke-width="10"
          stroke-miterlimit="10"
        />
        <path
          d="M43.968 100.4c6.112 0 13.196-.083 19.376-2.517 6.547-2.577 11.8-7.65 14.513-16.739l-7.666-2.288c-2.032 6.809-5.59 9.935-9.777 11.583C55.86 92.232 50.288 92.4 43.968 92.4c-8.16 0-15.898 3.418-22.056 8.52l2.566 3.097-2.573-3.091C13.81 107.664 8 117.829 8 128.368V184h8v-55.632c0-7.665 4.332-15.722 11.016-21.288 5.043-4.178 11.032-6.68 16.952-6.68Z"
          fill="#8B8AD1"
        />
        <path
          d="M22.896 92.608C16.44 88.672 12 80.68 12 64.432V8"
          stroke="#42C7BB"
          stroke-width="10"
          stroke-miterlimit="10"
        />
        <path
          d="M75.928 184v-55.632c0-7.296-3.256-14.592-8.344-20.376"
          fill="#fff"
        />
        <path
          d="M75.928 184v-55.632c0-7.296-3.256-14.592-8.344-20.376"
          stroke="#42C7BB"
          stroke-width="10"
          stroke-miterlimit="10"
        />
        <path d="M179.928 64.432V8v56.432Z" fill="#fff" />
        <path
          d="M179.928 64.432V8"
          stroke="#8B8AD1"
          stroke-width="10"
          stroke-miterlimit="10"
        />
        <path
          d="M147.968 100.4c6.112 0 13.196-.083 19.376-2.517 6.547-2.577 11.801-7.65 14.513-16.739l-7.666-2.288c-2.032 6.809-5.59 9.935-9.777 11.583-4.554 1.793-10.126 1.961-16.446 1.961-8.16 0-15.898 3.418-22.056 8.52l2.566 3.097-2.573-3.091C117.809 107.664 112 117.829 112 128.368V184h8v-55.632c0-7.665 4.332-15.722 11.016-21.288 5.043-4.178 11.033-6.68 16.952-6.68Z"
          fill="#42C7BB"
        />
        <path
          d="M126.896 92.608C120.44 88.672 116 80.68 116 64.432V8M179.928 184v-55.632c0-7.296-3.256-14.592-8.344-20.376"
          stroke="#8B8AD1"
          stroke-width="10"
          stroke-miterlimit="10"
        />
        <path d="M56 168H24a4 4 0 0 0 0 8h32a4 4 0 0 0 0-8Z" fill="#AFA2FF" />
        <path d="M64 152H32a4 4 0 0 0 0 8h32a4 4 0 0 0 0-8Z" fill="#423978" />
        <path d="M64 120H32a4 4 0 0 0 0 8h32a4 4 0 0 0 0-8Z" fill="#42C7BB" />
        <path d="M56 104H40a4 4 0 0 0 0 8h16a4 4 0 0 0 0-8Z" fill="#AAA3D2" />
        <path d="M56 80H40a4 4 0 0 0 0 8h16a4 4 0 0 0 0-8Z" fill="#fff" />
        <path d="M56 136H24a4 4 0 0 0 0 8h32a4 4 0 0 0 0-8Z" fill="#AFA2FF" />
        <path d="M56 64H24a4 4 0 0 0 0 8h32a4 4 0 0 0 0-8Z" fill="#AAA3D2" />
        <path
          d="M64 48H32a4 4 0 0 0 0 8h32a4 4 0 0 0 0-8ZM68 16H36a4 4 0 0 0 0 8h32a4 4 0 0 0 0-8Z"
          fill="#423978"
        />
        <path d="M60 32H28a4 4 0 0 0 0 8h32a4 4 0 0 0 0-8Z" fill="#AAA3D2" />
        <path d="M160 168h-32a4 4 0 0 0 0 8h32a4 4 0 0 0 0-8Z" fill="#5FDECC" />
        <path
          d="M168 152h-32a4 4 0 0 0 0 8h32a4 4 0 0 0 0-8ZM168 120h-32a4 4 0 0 0 0 8h32a4 4 0 0 0 0-8Z"
          fill="#3E6CA7"
        />
        <path d="M160 104h-16a4 4 0 0 0 0 8h16a4 4 0 0 0 0-8Z" fill="#5FDECC" />
        <path d="M160 80h-16a4 4 0 0 0 0 8h16a4 4 0 0 0 0-8Z" fill="#fff" />
        <path d="M160 136h-32a4 4 0 0 0 0 8h32a4 4 0 0 0 0-8Z" fill="#5FDECC" />
        <path d="M160 64h-32a4 4 0 0 0 0 8h32a4 4 0 0 0 0-8Z" fill="#3E6CA7" />
        <path
          d="M168 48h-32a4 4 0 0 0 0 8h32a4 4 0 0 0 0-8ZM168 16h-32a4 4 0 0 0 0 8h32a4 4 0 0 0 0-8Z"
          fill="#5FDECC"
        />
        <path d="M160 32h-32a4 4 0 0 0 0 8h32a4 4 0 0 0 0-8Z" fill="#3E6CA7" />
      </svg>

      <i *ngIf="isLoading" class="fas fa-spinner fa-spin"></i>
    </div>
    <input
      #input
      [placeholder]="
        location === 'header' ? 'Search genes' : 'Find Gene by Name...'
      "
      autocomplete="off"
      type="text"
      (focus)="onFocus()"
      [(ngModel)]="query"
    />
    <div class="gene-search-bar-close" *ngIf="query" >
      <button (click)="clearInput()">
        <svg
          width="10"
          height="10"
          viewBox="0 0 10 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 9L9 1M1 1L9 9"
            stroke="#C4C4C4"
            stroke-width="2"
          />
        </svg>
      </button>
    </div>
  </div>
  <div class="gene-search-results" *ngIf="showGeneResults && (error || results.length > 0)">
    <div class="gene-search-results-inner">
      <ul *ngIf="error" class="gene-search-errors">
        <li>
          <svg
            width="22"
            height="22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity=".3"
              d="M11 3.667A7.331 7.331 0 0 0 3.667 11 7.331 7.331 0 0 0 11 18.333 7.331 7.331 0 0 0 18.334 11 7.331 7.331 0 0 0 11 3.667Zm.917 11.916h-1.833V13.75h1.833v1.833Zm0-3.666h-1.833v-5.5h1.833v5.5Z"
              fill="#CC9F00"
            />
            <path
              d="M10.99 1.833C5.93 1.833 1.834 5.94 1.834 11s4.098 9.167 9.158 9.167c5.069 0 9.175-4.107 9.175-9.167s-4.106-9.167-9.175-9.167Zm.01 16.5A7.331 7.331 0 0 1 3.666 11 7.331 7.331 0 0 1 11 3.667 7.331 7.331 0 0 1 18.333 11 7.331 7.331 0 0 1 11 18.333Zm-.917-4.583h1.833v1.833h-1.833V13.75Zm0-7.333h1.833v5.5h-1.833v-5.5Z"
              fill="#CC9F00"
            />
          </svg>
          {{ error }}
        </li>
      </ul>

      <ul *ngIf="!error && results.length" class="gene-search-results-list">
        <li
          *ngFor="let result of results"
          (click)="goToGene(result.ensembl_gene_id)"
        >
          <span class="gene-id">
            {{ result.hgnc_symbol || result.ensembl_gene_id }}
          </span>
          <span
            *ngIf="
              result.hgnc_symbol && hgncSymbolCounts[result.hgnc_symbol] > 1
            "
            class="gene-ensembl-id"
          >
            &nbsp;{{ result.ensembl_gene_id }}
          </span>
          <span *ngIf="hasAlias(result)" class="gene-aliases">
            &nbsp;(Also known as&nbsp;{{ query.toUpperCase() }})
          </span>
        </li>
      </ul>
    </div>
  </div>
</div>
