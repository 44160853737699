<div *ngIf="gene" class="gene-details">
  <gene-hero [gene]="gene"></gene-hero>

  <div
    class="gene-details-nav"
    [ngClass]="{ 'has-active-child': activeParent }"
  >
    <div class="gene-details-nav-inner">
      <button
        *ngIf="navSlideIndex > 0"
        class="gene-details-nav-scroll gene-details-nav-scroll-prev"
        (click)="slideNavigation(-1)"
      >
        <i class="fa-solid fa-angle-left"></i>
      </button>
      <div class="gene-details-nav-container">
        <ul>
          <ng-container *ngFor="let item of panels">
            <li
              *ngIf="!item.disabled"
              class="gene-details-nav-item"
              [ngClass]="{
                active: activePanel === item.name || activeParent === item.name
              }"
            >
              <a (click)="onNavigationItemClick(item)">
                {{ item.label }}
                <span class="gene-details-nav-icon"></span>
              </a>
              <div *ngIf="item.children" class="gene-details-subnav">
                <ul>
                  <ng-container *ngFor="let subItem of item.children">
                    <li
                      *ngIf="!subItem.disabled"
                      class="gene-details-subnav-item"
                      [ngClass]="{ active: activePanel === subItem.name }"
                    >
                      <a (click)="onNavigationItemClick(subItem)">
                        {{ subItem.label }}
                        <span class="gene-details-nav-icon"></span>
                      </a>
                    </li>
                  </ng-container>
                </ul>
              </div>
            </li>
          </ng-container>
        </ul>
      </div>
      <button
        *ngIf="navSlideIndex < getPanelCount() - 1"
        class="gene-details-nav-scroll gene-details-nav-scroll-next"
        (click)="slideNavigation(1)"
      >
        <i class="fa-solid fa-angle-right"></i>
      </button>
    </div>
  </div>

  <div class="gene-details-body">
    <ng-template #panelTemplate let-panel>
      <div
        *ngIf="!panel.disabled"
        class="gene-details-panel"
        [ngClass]="{
          active: activePanel === panel.name
        }"
      >
        <gene-soe *ngIf="'summary' == panel.name" [gene]="gene"></gene-soe>

        <gene-evidence-rna
          *ngIf="'rna' == panel.name"
          [gene]="gene"
        ></gene-evidence-rna>

        <gene-evidence-proteomics
          *ngIf="'protein' == panel.name"
          [gene]="gene"
        ></gene-evidence-proteomics>

        <gene-evidence-metabolomics
          *ngIf="'metabolomics' == panel.name"
          [gene]="gene"
        ></gene-evidence-metabolomics>

        <gene-resources
          *ngIf="'resources' == panel.name"
          [gene]="gene"
        ></gene-resources>

        <gene-nominations
          *ngIf="'nominations' == panel.name"
          [gene]="gene"
        ></gene-nominations>

        <gene-experimental-validation
          *ngIf="'experimental-validation' == panel.name"
          [gene]="gene"
        ></gene-experimental-validation>
      </div>
    </ng-template>

    <ng-container *ngFor="let panel of panels">
      <ng-container
        *ngTemplateOutlet="panelTemplate; context: { $implicit: panel }"
      ></ng-container>
      <ng-container *ngIf="panel.children">
        <ng-container *ngFor="let childPanel of panel.children">
          <ng-container
            *ngTemplateOutlet="
              panelTemplate;
              context: { $implicit: childPanel }
            "
          ></ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>
