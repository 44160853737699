<div *ngIf="_gene" class="gene-score-charts">
  <div *ngFor="let chart of scoreDistributions">
    <div class="gene-score-charts-header">
      <div>
        <h2 class="gene-score-charts-heading">
          {{ chart.name }}
        </h2>
      </div>
      <div>
        <overlay-panel-link
          [ownerId]="chart.syn_id" 
          [wikiId]="chart.wiki_id">
        </overlay-panel-link>
      </div>
    </div>
    <score-barchart
      [score]="getGeneOverallScores(chart.name)"
      [barColor]="getBarColor(chart.name)"
      [data]="chart"
    ></score-barchart>
  </div>
</div>
