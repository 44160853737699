<div id="home-page">
  <div class="section">
    <div class="background">
      <svg viewBox="0 0 60 54" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="m58.939 29.085-.454.807-2.16 3.839-3.807 6.768-6.38 11.34A4.243 4.243 0 0 1 42.447 54H17.037a4.232 4.232 0 0 1-3.692-2.161l-1.988-3.535-6.408-11.391-4.404-7.827a4.254 4.254 0 0 1 0-4.171l7.118-12.653 5.683-10.1A4.244 4.244 0 0 1 17.038 0h25.409a4.232 4.232 0 0 1 3.692 2.161l12.8 22.753a4.253 4.253 0 0 1 0 4.171Z"
          fill="#fff"
        />
        <path
          d="m26.816 22.64-6.629 11.794-6.67 11.867a4.81 4.81 0 0 1-2.16 2.004L4.95 36.914.545 29.086a4.254 4.254 0 0 1 0-4.17l7.118-12.653 5.683-10.1A4.244 4.244 0 0 1 16.756.01l9.987 17.772.072.127a4.83 4.83 0 0 1 0 4.732Z"
          fill="#42C7BB"
          style="mix-blend-mode: multiply"
        />
        <path
          d="m56.326 33.731-3.808 6.768-6.379 11.34A4.243 4.243 0 0 1 42.447 54h-25.41a4.233 4.233 0 0 1-3.691-2.161l-1.989-3.535L4.95 36.913l8.322-14.69 1.133-2.001a4.816 4.816 0 0 1 4.184-2.442H44.49a4.803 4.803 0 0 1 4.185 2.442l5.478 9.67 2.173 3.839Z"
          fill="#B2A5D1"
          style="mix-blend-mode: multiply"
        />
        <path
          d="m58.94 29.085-.454.807-2.16 3.839-3.807 6.768H26.426a4.803 4.803 0 0 1-4.184-2.442l-2.054-3.626-6.917-12.21-5.365-9.47a4.773 4.773 0 0 1-.242-.489l5.683-10.1A4.244 4.244 0 0 1 17.039 0h25.409a4.232 4.232 0 0 1 3.692 2.161l12.8 22.753a4.254 4.254 0 0 1 0 4.171Z"
          fill="#73C8CC"
          style="mix-blend-mode: multiply"
        />
        <path
          d="m58.485 29.893-2.159 3.838-3.808 6.769-6.379 11.339A4.243 4.243 0 0 1 42.447 54H30.185c.06-.688.268-1.355.608-1.955L37.327 40.5l4.62-8.164a4.815 4.815 0 0 1 4.185-2.442l12.353-.001Z"
          fill="#C7D6FF"
          style="mix-blend-mode: multiply"
        />
      </svg>
      <svg viewBox="0 0 60 54" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="m58.939 29.085-.454.807-2.16 3.839-3.807 6.768-6.38 11.34A4.243 4.243 0 0 1 42.447 54H17.037a4.232 4.232 0 0 1-3.692-2.161l-1.988-3.535-6.408-11.391-4.404-7.827a4.254 4.254 0 0 1 0-4.171l7.118-12.653 5.683-10.1A4.244 4.244 0 0 1 17.038 0h25.409a4.232 4.232 0 0 1 3.692 2.161l12.8 22.753a4.253 4.253 0 0 1 0 4.171Z"
          fill="#fff"
        />
        <path
          d="m26.816 22.64-6.629 11.794-6.67 11.867a4.81 4.81 0 0 1-2.16 2.004L4.95 36.914.545 29.086a4.254 4.254 0 0 1 0-4.17l7.118-12.653 5.683-10.1A4.244 4.244 0 0 1 16.756.01l9.987 17.772.072.127a4.83 4.83 0 0 1 0 4.732Z"
          fill="#42C7BB"
          style="mix-blend-mode: multiply"
        />
        <path
          d="m56.326 33.731-3.808 6.768-6.379 11.34A4.243 4.243 0 0 1 42.447 54h-25.41a4.233 4.233 0 0 1-3.691-2.161l-1.989-3.535L4.95 36.913l8.322-14.69 1.133-2.001a4.816 4.816 0 0 1 4.184-2.442H44.49a4.803 4.803 0 0 1 4.185 2.442l5.478 9.67 2.173 3.839Z"
          fill="#B2A5D1"
          style="mix-blend-mode: multiply"
        />
        <path
          d="m58.94 29.085-.454.807-2.16 3.839-3.807 6.768H26.426a4.803 4.803 0 0 1-4.184-2.442l-2.054-3.626-6.917-12.21-5.365-9.47a4.773 4.773 0 0 1-.242-.489l5.683-10.1A4.244 4.244 0 0 1 17.039 0h25.409a4.232 4.232 0 0 1 3.692 2.161l12.8 22.753a4.254 4.254 0 0 1 0 4.171Z"
          fill="#73C8CC"
          style="mix-blend-mode: multiply"
        />
        <path
          d="m58.485 29.893-2.159 3.838-3.808 6.769-6.379 11.339A4.243 4.243 0 0 1 42.447 54H30.185c.06-.688.268-1.355.608-1.955L37.327 40.5l4.62-8.164a4.815 4.815 0 0 1 4.185-2.442l12.353-.001Z"
          fill="#C7D6FF"
          style="mix-blend-mode: multiply"
        />
      </svg>
      <svg viewBox="0 0 60 54" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="m58.939 29.085-.454.807-2.16 3.839-3.807 6.768-6.38 11.34A4.243 4.243 0 0 1 42.447 54H17.037a4.232 4.232 0 0 1-3.692-2.161l-1.988-3.535-6.408-11.391-4.404-7.827a4.254 4.254 0 0 1 0-4.171l7.118-12.653 5.683-10.1A4.244 4.244 0 0 1 17.038 0h25.409a4.232 4.232 0 0 1 3.692 2.161l12.8 22.753a4.253 4.253 0 0 1 0 4.171Z"
          fill="#fff"
        />
        <path
          d="m26.816 22.64-6.629 11.794-6.67 11.867a4.81 4.81 0 0 1-2.16 2.004L4.95 36.914.545 29.086a4.254 4.254 0 0 1 0-4.17l7.118-12.653 5.683-10.1A4.244 4.244 0 0 1 16.756.01l9.987 17.772.072.127a4.83 4.83 0 0 1 0 4.732Z"
          fill="#42C7BB"
          style="mix-blend-mode: multiply"
        />
        <path
          d="m56.326 33.731-3.808 6.768-6.379 11.34A4.243 4.243 0 0 1 42.447 54h-25.41a4.233 4.233 0 0 1-3.691-2.161l-1.989-3.535L4.95 36.913l8.322-14.69 1.133-2.001a4.816 4.816 0 0 1 4.184-2.442H44.49a4.803 4.803 0 0 1 4.185 2.442l5.478 9.67 2.173 3.839Z"
          fill="#B2A5D1"
          style="mix-blend-mode: multiply"
        />
        <path
          d="m58.94 29.085-.454.807-2.16 3.839-3.807 6.768H26.426a4.803 4.803 0 0 1-4.184-2.442l-2.054-3.626-6.917-12.21-5.365-9.47a4.773 4.773 0 0 1-.242-.489l5.683-10.1A4.244 4.244 0 0 1 17.039 0h25.409a4.232 4.232 0 0 1 3.692 2.161l12.8 22.753a4.254 4.254 0 0 1 0 4.171Z"
          fill="#73C8CC"
          style="mix-blend-mode: multiply"
        />
        <path
          d="m58.485 29.893-2.159 3.838-3.808 6.769-6.379 11.339A4.243 4.243 0 0 1 42.447 54H30.185c.06-.688.268-1.355.608-1.955L37.327 40.5l4.62-8.164a4.815 4.815 0 0 1 4.185-2.442l12.353-.001Z"
          fill="#C7D6FF"
          style="mix-blend-mode: multiply"
        />
      </svg>
    </div>
    <div class="content">
      <div class="text-marquee">
        <div>
          <h1 class="home-heading">
            Discover Alzheimer&#39;s Disease Genes
          </h1>
        </div>
        <p class="description mb-lg">
          Agora hosts high-dimensional human transcriptomic, proteomic, and metabolomic 
          evidence for whether or not genes are associated with Alzheimer’s disease (AD). 
          Agora also contains a list of over 900 nascent AD drug targets that 
          were nominated by researchers from the National Institute on Aging's 
          <a class="link" href="https://www.nia.nih.gov/research/amp-ad" target="_blank">AMP-AD</a> 
          consortium and <a class="link" href="https://treatad.org/" target="_blank">TREAT-AD</a>
          centers, as well as by other AD research teams.
          <a
            class="link"
            href="https://help.adknowledgeportal.org/apd/Agora-Resources.2646671361.html"
            target="_blank"
          >
            Learn more
          </a>
        </p>
      </div>
      <div class="card-container">
        <button routerLink="/genes/comparison" class="card-link">
          <div class="card">
            <div>
              <h2>Gene Comparison</h2>
              <p>
                Compare differential RNA and protein expression results for 20k+
                human genes in AD. Build custom result sets by sorting, filtering, and
                searching for genes of interest.
              </p>
            </div>
            <div class="icon-container">
              <svg
                viewBox="0 0 144 144"
                width="100"
                height="100"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M36 6H18a3 3 0 1 0 0 6h18a3 3 0 1 0 0-6ZM36 48H18a3 3 0 1 0 0 6h18a3 3 0 1 0 0-6ZM36 78H18a3 3 0 1 0 0 6h18a3 3 0 1 0 0-6Z"
                  fill="#42C7BB"
                />
                <path
                  d="M36 90H18a3 3 0 1 0 0 6h18a3 3 0 1 0 0-6ZM36 120H18a3 3 0 1 0 0 6h18a3 3 0 1 0 0-6Z"
                  fill="#3E6CA7"
                />
                <path
                  d="M36 132H18a3 3 0 1 0 0 6h18a3 3 0 1 0 0-6Z"
                  fill="#42C7BB"
                />
                <path
                  d="M36 18H18a3 3 0 0 0-3 3v18a3 3 0 0 0 3 3h18a3 3 0 0 0 3-3V21a3 3 0 0 0-3-3Z"
                  fill="#8B8AD1"
                />
                <path
                  d="M36 60H18a3 3 0 0 0-3 3v6a3 3 0 0 0 3 3h18a3 3 0 0 0 3-3v-6a3 3 0 0 0-3-3Z"
                  fill="#423978"
                />
                <path
                  d="M36 102H18a3 3 0 0 0-3 3v6a3 3 0 0 0 3 3h18a3 3 0 0 0 3-3v-6a3 3 0 0 0-3-3Z"
                  fill="#8B8AD1"
                />
                <path
                  d="M126 6h-18a3 3 0 1 0 0 6h18a3 3 0 1 0 0-6ZM126 66h-18a3 3 0 1 0 0 6h18a3 3 0 1 0 0-6Z"
                  fill="#42C7BB"
                />
                <path
                  d="M126 78h-18a3 3 0 1 0 0 6h18a3 3 0 1 0 0-6Z"
                  fill="#3E6CA7"
                />
                <path
                  d="M126 90h-18a3 3 0 1 0 0 6h18a3 3 0 1 0 0-6Z"
                  fill="#42C7BB"
                />
                <path
                  d="M126 120h-18a3 3 0 1 0 0 6h18a3 3 0 1 0 0-6Z"
                  fill="#3E6CA7"
                />
                <path
                  d="M126 132h-18a3 3 0 1 0 0 6h18a3 3 0 1 0 0-6Z"
                  fill="#42C7BB"
                />
                <path
                  d="M126 18h-18a3 3 0 0 0-3 3v18a3 3 0 0 0 3 3h18a3 3 0 0 0 3-3V21a3 3 0 0 0-3-3Z"
                  fill="#423978"
                />
                <path
                  d="M126 48h-18a3 3 0 0 0-3 3v6a3 3 0 0 0 3 3h18a3 3 0 0 0 3-3v-6a3 3 0 0 0-3-3Z"
                  fill="#8B8AD1"
                />
                <path
                  d="M126 102h-18a3 3 0 0 0-3 3v6a3 3 0 0 0 3 3h18a3 3 0 0 0 3-3v-6a3 3 0 0 0-3-3Z"
                  fill="#423978"
                />
                <path
                  d="M48 138h18a3 3 0 1 0 0-6H48a3 3 0 1 0 0 6Z"
                  fill="#42C7BB"
                />
                <path
                  d="M48 54h18a3 3 0 1 0 0-6H48a3 3 0 1 0 0 6Z"
                  fill="#3E6CA7"
                />
                <path
                  d="M78 54h18a3 3 0 1 0 0-6H78a3 3 0 1 0 0 6Z"
                  fill="#42C7BB"
                />
                <path
                  d="M78 66h18a3 3 0 1 0 0-6H78a3 3 0 1 0 0 6Z"
                  fill="#5FDECC"
                />
                <path
                  d="M48 24h18a3 3 0 1 0 0-6H48a3 3 0 1 0 0 6Z"
                  fill="#3E6CA7"
                />
                <path
                  d="M48 12h18a3 3 0 1 0 0-6H48a3 3 0 1 0 0 6Z"
                  fill="#42C7BB"
                />
                <path
                  d="M48 126h18a3 3 0 0 0 3-3v-18a3 3 0 0 0-3-3H48a3 3 0 0 0-3 3v18a3 3 0 0 0 3 3Z"
                  fill="#B09CF8"
                />
                <path
                  d="M48 42h18a3 3 0 0 0 3-3v-6a3 3 0 0 0-3-3H48a3 3 0 0 0-3 3v6a3 3 0 0 0 3 3Z"
                  fill="#423978"
                />
                <path
                  d="M96 6H78a3 3 0 1 0 0 6h18a3 3 0 1 0 0-6ZM96 114H78a3 3 0 1 0 0 6h18a3 3 0 1 0 0-6Z"
                  fill="#42C7BB"
                />
                <path
                  d="M96 18H78a3 3 0 0 0-3 3v18a3 3 0 0 0 3 3h18a3 3 0 0 0 3-3V21a3 3 0 0 0-3-3Z"
                  fill="#8B8AD1"
                />
                <path
                  d="M96 126H78a3 3 0 0 0-3 3v6a3 3 0 0 0 3 3h18a3 3 0 0 0 3-3v-6a3 3 0 0 0-3-3Z"
                  fill="#423978"
                />
                <path
                  d="M87.024 103.8V77.01"
                  stroke="#5FDECC"
                  stroke-width="10"
                  stroke-miterlimit="10"
                />
                <path
                  d="M78.516 83.4 87 74.916l8.484 8.484"
                  stroke="#5FDECC"
                  stroke-width="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M56.976 62.316v26.79"
                  stroke="#B09CF8"
                  stroke-width="10"
                  stroke-miterlimit="10"
                />
                <path
                  d="M65.484 82.716 57 91.2l-8.484-8.484"
                  stroke="#B09CF8"
                  stroke-width="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <svg
                width="35"
                height="33"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M.857 16.787h30M16.858 2.716 31 16.858 16.858 31"
                  stroke="#5BB0B5"
                  stroke-width="5"
                />
              </svg>
            </div>
          </div>
        </button>
        <div class="card">
          <div>
            <h2>Gene Search</h2>
            <p>
              Search for a gene by name or Ensembl gene ID to view related experimental 
              evidence, find detailed information about nominations, and explore its 
              association with AD.
            </p>
          </div>
          <gene-search [location]="'home'"></gene-search>
        </div>
        <button routerLink="/genes/nominated-targets" class="card-link">
          <div class="card">
            <div>
              <h2>Nominated Targets</h2>
              <p>
                Browse the list of over 900 genes that research teams have nominated as 
                potential new therapeutic targets for AD treatment or prevention.
              </p>
            </div>
            <div class="icon-container">
              <svg
                viewBox="0 0 139 139"
                width="70"
                height="70"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M60.757 26.165c4.794 4.794 12.566 4.794 17.36 0 4.793-4.793 4.793-12.565 0-17.359-4.794-4.793-12.566-4.793-17.36 0-4.794 4.794-4.794 12.566 0 17.36ZM86.795 52.204c4.794 4.794 12.566 4.794 17.359 0 4.794-4.793 4.794-12.565 0-17.359-4.793-4.793-12.565-4.793-17.359 0-4.794 4.794-4.794 12.566 0 17.36ZM112.834 78.243c4.794 4.793 12.566 4.793 17.359 0 4.794-4.794 4.794-12.566 0-17.36-4.793-4.793-12.565-4.793-17.359 0-4.793 4.794-4.793 12.566 0 17.36ZM34.719 52.204c4.794 4.794 12.565 4.794 17.359 0 4.793-4.793 4.793-12.565 0-17.359-4.794-4.793-12.566-4.793-17.36 0-4.793 4.794-4.793 12.566 0 17.36Z"
                  fill="#3E6CA7"
                />
                <path
                  d="M60.757 78.243c4.794 4.793 12.566 4.793 17.36 0 4.793-4.794 4.793-12.566 0-17.36-4.794-4.793-12.566-4.793-17.36 0-4.794 4.794-4.794 12.566 0 17.36Z"
                  fill="#42C7BB"
                />
                <path
                  d="M86.795 104.282c4.794 4.793 12.566 4.793 17.359 0 4.794-4.794 4.794-12.566 0-17.36-4.793-4.793-12.565-4.793-17.359 0-4.794 4.794-4.794 12.566 0 17.36ZM8.68 78.243c4.793 4.793 12.565 4.793 17.359 0 4.793-4.794 4.793-12.566 0-17.36-4.794-4.793-12.566-4.793-17.36 0-4.793 4.794-4.793 12.566 0 17.36ZM34.719 104.282c4.794 4.793 12.565 4.793 17.359 0 4.793-4.794 4.793-12.566 0-17.36-4.794-4.793-12.566-4.793-17.36 0-4.793 4.794-4.793 12.566 0 17.36ZM60.757 130.321c4.794 4.793 12.566 4.793 17.36 0 4.793-4.794 4.793-12.566 0-17.359-4.794-4.794-12.566-4.794-17.36 0-4.794 4.793-4.794 12.565 0 17.359Z"
                  fill="#3E6CA7"
                />
              </svg>
              <svg
                width="35"
                height="33"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M.857 16.787h30M16.858 2.716 31 16.858 16.858 31"
                  stroke="#5BB0B5"
                  stroke-width="5"
                />
              </svg>
            </div>
          </div>
        </button>
      </div>
    </div>
  </div>
</div>
